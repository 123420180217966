// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/commons/LinkTemplate.soy

/**
 * @fileoverview Templates in namespace ts.commons.LinkTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import * as google from 'ts-closure-library/lib/google';
import * as goog_soy_data from 'soyutils/soyutils_usegoog';
import * as soy from 'soyutils/soyutils_usegoog';


/**
 * @param {!$baselines.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $baselines = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.baselines']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.baselines'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  return $uniformLink(soy.$$assignDefaults({perspective: 'qualitycontrol', viewName: 'baselines'}, opt_data), $ijData);
};
export { $baselines as baselines };
/**
 * @typedef {{
 *  project: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  uniformPath?: (null|string|undefined),
 *  parameters?: (?|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$baselines.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $baselines.soyTemplateName = 'ts.commons.LinkTemplate.baselines';
}


/**
 * @param {!$findingDetails.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $findingDetails = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.findingDetails']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.findingDetails'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {string} */
  const id = soy.assertParamType(typeof opt_data.id === 'string', 'id', opt_data.id, '@param', 'string');
  /** @type {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} */
  const commit = soy.assertParamType(opt_data.commit == null || google.isObject(opt_data.commit), 'commit', opt_data.commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  /** @type {null|string|undefined} */
  const searchParamsId = soy.assertParamType(opt_data.searchParamsId == null || typeof opt_data.searchParamsId === 'string', 'searchParamsId', opt_data.searchParamsId, '@param', 'null|string|undefined');
  return $uniformLink(soy.$$assignDefaults({perspective: 'findings', viewName: 'details', parameters: {id: id, searchParamsId: searchParamsId}}, opt_data), $ijData);
};
export { $findingDetails as findingDetails };
/**
 * @typedef {{
 *  project: string,
 *  id: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  searchParamsId?: (null|string|undefined),
 *  uniformPath?: (null|string|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$findingDetails.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $findingDetails.soyTemplateName = 'ts.commons.LinkTemplate.findingDetails';
}


/**
 * @param {!$findingsList.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $findingsList = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.findingsList']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.findingsList'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {null|string|undefined} */
  const uniformPath = soy.assertParamType(opt_data.uniformPath == null || typeof opt_data.uniformPath === 'string', 'uniformPath', opt_data.uniformPath, '@param', 'null|string|undefined');
  /** @type {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} */
  const commit = soy.assertParamType(opt_data.commit == null || google.isObject(opt_data.commit), 'commit', opt_data.commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  /** @type {?} */
  const parameters = opt_data.parameters;
  return $uniformLink(soy.$$assignDefaults({perspective: 'findings', viewName: 'list', parameters: parameters}, opt_data), $ijData);
};
export { $findingsList as findingsList };
/**
 * @typedef {{
 *  project: string,
 *  uniformPath?: (null|string|undefined),
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  parameters?: (?|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$findingsList.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $findingsList.soyTemplateName = 'ts.commons.LinkTemplate.findingsList';
}


/**
 * @param {!$code.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $code = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.code']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.code'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {string} */
  const uniformPath = soy.assertParamType(typeof opt_data.uniformPath === 'string', 'uniformPath', opt_data.uniformPath, '@param', 'string');
  /** @type {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} */
  const commit = soy.assertParamType(opt_data.commit == null || google.isObject(opt_data.commit), 'commit', opt_data.commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  /** @type {!Array<string>|null|undefined} */
  const findingFilter = soy.assertParamType(opt_data.findingFilter == null || Array.isArray(opt_data.findingFilter), 'findingFilter', opt_data.findingFilter, '@param', '!Array<string>|null|undefined');
  /** @type {?} */
  const selection = opt_data.selection;
  /** @type {null|string|undefined} */
  const identifier = soy.assertParamType(opt_data.identifier == null || typeof opt_data.identifier === 'string', 'identifier', opt_data.identifier, '@param', 'null|string|undefined');
  /** @type {null|string|undefined} */
  const highlight = soy.assertParamType(opt_data.highlight == null || typeof opt_data.highlight === 'string', 'highlight', opt_data.highlight, '@param', 'null|string|undefined');
  /** @type {null|string|undefined} */
  const thresholdProfile = soy.assertParamType(opt_data.thresholdProfile == null || typeof opt_data.thresholdProfile === 'string', 'thresholdProfile', opt_data.thresholdProfile, '@param', 'null|string|undefined');
  /** @type {null|string|undefined} */
  const visibleMetric = soy.assertParamType(opt_data.visibleMetric == null || typeof opt_data.visibleMetric === 'string', 'visibleMetric', opt_data.visibleMetric, '@param', 'null|string|undefined');
  /** @type {boolean|null|undefined} */
  const highlightMetric = soy.assertParamType(opt_data.highlightMetric == null || typeof opt_data.highlightMetric === 'boolean', 'highlightMetric', opt_data.highlightMetric, '@param', 'boolean|null|undefined');
  /** @type {boolean|null|undefined} */
  const showCoverage = soy.assertParamType(opt_data.showCoverage == null || typeof opt_data.showCoverage === 'boolean', 'showCoverage', opt_data.showCoverage, '@param', 'boolean|null|undefined');
  /** @type {null|string|undefined} */
  const targetPerspectiveName = soy.assertParamType(opt_data.targetPerspectiveName == null || typeof opt_data.targetPerspectiveName === 'string', 'targetPerspectiveName', opt_data.targetPerspectiveName, '@param', 'null|string|undefined');
  /** @type {null|string|undefined} */
  const targetViewName = soy.assertParamType(opt_data.targetViewName == null || typeof opt_data.targetViewName === 'string', 'targetViewName', opt_data.targetViewName, '@param', 'null|string|undefined');
  let $output = '';
  let $tmp;
  if (!targetPerspectiveName && (soy.$$checkNotNull(uniformPath).startsWith('-test-implementation-/') || soy.$$checkNotNull(uniformPath).startsWith('-test-execution-/'))) {
    $tmp = 'tests';
  } else if (targetViewName) {
    $tmp = '' + targetViewName;
  } else {
    $tmp = 'code';
  }
  let targetView__soy13398 = '' + $tmp;
  let perspectiveName__soy13406 = '' + (targetPerspectiveName ? '' + targetPerspectiveName : uniformPath == '-architectures-' ? 'architecture' : 'metrics');
  let $tmp$$2;
  if (selection) {
    let $tmp$$1;
    if (selection.startOffset >= 0 && selection.endOffset >= 0) {
      $tmp$$1 = 'char-' + '' + selection.startOffset + '-' + '' + selection.endOffset;
    } else if (soy.$$coerceToBoolean(selection.startLine) && soy.$$coerceToBoolean(selection.endLine)) {
      $tmp$$1 = '' + selection.startLine + '-' + '' + selection.endLine;
    } else if (soy.$$coerceToBoolean(selection.firstLine) && soy.$$coerceToBoolean(selection.lastLine)) {
      $tmp$$1 = '' + selection.firstLine + '-' + '' + selection.lastLine;
    } else {
      $tmp$$1 = '';
    }
    $tmp$$2 = $tmp$$1;
  } else {
    $tmp$$2 = '';
  }
  let selectionParameter__soy13416 = '' + $tmp$$2;
  $output += $uniformLink(soy.$$assignDefaults({perspective: perspectiveName__soy13406, viewName: targetView__soy13398, parameters: {selection: selectionParameter__soy13416, filter: findingFilter, identifier: identifier, highlight: highlight != null ? highlight : '', profile: thresholdProfile != null ? thresholdProfile : '', visibleMetric: visibleMetric, highlightMetric: highlightMetric, showCoverage: showCoverage}}, opt_data), $ijData);
  return $output;
};
export { $code as code };
/**
 * @typedef {{
 *  project: string,
 *  uniformPath: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  findingFilter?: (!Array<string>|null|undefined),
 *  selection?: (?|undefined),
 *  identifier?: (null|string|undefined),
 *  highlight?: (null|string|undefined),
 *  thresholdProfile?: (null|string|undefined),
 *  visibleMetric?: (null|string|undefined),
 *  highlightMetric?: (boolean|null|undefined),
 *  showCoverage?: (boolean|null|undefined),
 *  targetPerspectiveName?: (null|string|undefined),
 *  targetViewName?: (null|string|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$code.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $code.soyTemplateName = 'ts.commons.LinkTemplate.code';
}


/**
 * @param {!$architectureMetrics.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $architectureMetrics = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.architectureMetrics']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.architectureMetrics'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {string} */
  const uniformPath = soy.assertParamType(typeof opt_data.uniformPath === 'string', 'uniformPath', opt_data.uniformPath, '@param', 'string');
  /** @type {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} */
  const commit = soy.assertParamType(opt_data.commit == null || google.isObject(opt_data.commit), 'commit', opt_data.commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  /** @type {null|string|undefined} */
  const thresholdProfile = soy.assertParamType(opt_data.thresholdProfile == null || typeof opt_data.thresholdProfile === 'string', 'thresholdProfile', opt_data.thresholdProfile, '@param', 'null|string|undefined');
  /** @type {null|string|undefined} */
  const visibleMetric = soy.assertParamType(opt_data.visibleMetric == null || typeof opt_data.visibleMetric === 'string', 'visibleMetric', opt_data.visibleMetric, '@param', 'null|string|undefined');
  /** @type {null|string|undefined} */
  const targetPerspectiveName = soy.assertParamType(opt_data.targetPerspectiveName == null || typeof opt_data.targetPerspectiveName === 'string', 'targetPerspectiveName', opt_data.targetPerspectiveName, '@param', 'null|string|undefined');
  return $uniformLink(soy.$$assignDefaults({perspective: targetPerspectiveName != null ? targetPerspectiveName : 'metrics', viewName: 'architecture', parameters: {thresholdProfile: thresholdProfile != null ? thresholdProfile : '', visibleMetric: visibleMetric}}, opt_data), $ijData);
};
export { $architectureMetrics as architectureMetrics };
/**
 * @typedef {{
 *  project: string,
 *  uniformPath: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  thresholdProfile?: (null|string|undefined),
 *  visibleMetric?: (null|string|undefined),
 *  targetPerspectiveName?: (null|string|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$architectureMetrics.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $architectureMetrics.soyTemplateName = 'ts.commons.LinkTemplate.architectureMetrics';
}


/**
 * @param {!$compareLocationsInFiles.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $compareLocationsInFiles = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $compareLocationsInFiles$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.leftUniformPath, opt_data.leftStartLine, opt_data.leftEndLine, opt_data.rightUniformPath, opt_data.rightStartLine, opt_data.rightEndLine, opt_data.commit);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {string} leftUniformPath
 * @param {number} leftStartLine
 * @param {number} leftEndLine
 * @param {string} rightUniformPath
 * @param {number} rightStartLine
 * @param {number} rightEndLine
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @return {string}
 * @suppress {checkTypes}
 */
const $compareLocationsInFiles$ = function($$areYouAnInternalCaller, $ijData, project, leftUniformPath, leftStartLine, leftEndLine, rightUniformPath, rightStartLine, rightEndLine, commit) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.compareLocationsInFiles']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.compareLocationsInFiles']({project: project, leftUniformPath: leftUniformPath, leftStartLine: leftStartLine, leftEndLine: leftEndLine, rightUniformPath: rightUniformPath, rightStartLine: rightStartLine, rightEndLine: rightEndLine, commit: commit}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(typeof leftUniformPath === 'string', 'leftUniformPath', leftUniformPath, '@param', 'string');
  soy.assertParamType(typeof leftStartLine === 'number', 'leftStartLine', leftStartLine, '@param', 'number');
  soy.assertParamType(typeof leftEndLine === 'number', 'leftEndLine', leftEndLine, '@param', 'number');
  soy.assertParamType(typeof rightUniformPath === 'string', 'rightUniformPath', rightUniformPath, '@param', 'string');
  soy.assertParamType(typeof rightStartLine === 'number', 'rightStartLine', rightStartLine, '@param', 'number');
  soy.assertParamType(typeof rightEndLine === 'number', 'rightEndLine', rightEndLine, '@param', 'number');
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  return $comparePerspective$(soy.$$internalCallMarkerDoNotUse, $ijData, project, leftUniformPath, project, rightUniformPath, commit, leftStartLine, leftEndLine, commit, rightStartLine, rightEndLine);
};
export { $compareLocationsInFiles as compareLocationsInFiles };
export { $compareLocationsInFiles$ as compareLocationsInFiles$ };
/**
 * @typedef {{
 *  project: string,
 *  leftUniformPath: string,
 *  leftStartLine: number,
 *  leftEndLine: number,
 *  rightUniformPath: string,
 *  rightStartLine: number,
 *  rightEndLine: number,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 * }}
 */
$compareLocationsInFiles.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $compareLocationsInFiles.soyTemplateName = 'ts.commons.LinkTemplate.compareLocationsInFiles';
}


/**
 * @param {!$comparePerspective.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @private
 */
const $comparePerspective = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $comparePerspective$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.leftProject, opt_data.leftUniformPath, opt_data.rightProject, opt_data.rightUniformPath, opt_data.leftCommit, opt_data.leftStartLine, opt_data.leftEndLine, opt_data.rightCommit, opt_data.rightStartLine, opt_data.rightEndLine, opt_data.isInconsistentClone);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} leftProject
 * @param {string} leftUniformPath
 * @param {string} rightProject
 * @param {string} rightUniformPath
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} leftCommit
 * @param {null|number|undefined=} leftStartLine
 * @param {null|number|undefined=} leftEndLine
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} rightCommit
 * @param {null|number|undefined=} rightStartLine
 * @param {null|number|undefined=} rightEndLine
 * @param {boolean|null|undefined=} isInconsistentClone
 * @return {string}
 * @private
 * @suppress {checkTypes}
 */
const $comparePerspective$ = function($$areYouAnInternalCaller, $ijData, leftProject, leftUniformPath, rightProject, rightUniformPath, leftCommit, leftStartLine, leftEndLine, rightCommit, rightStartLine, rightEndLine, isInconsistentClone) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.comparePerspective']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.comparePerspective']({leftProject: leftProject, leftUniformPath: leftUniformPath, leftCommit: leftCommit, leftStartLine: leftStartLine, leftEndLine: leftEndLine, rightProject: rightProject, rightUniformPath: rightUniformPath, rightCommit: rightCommit, rightStartLine: rightStartLine, rightEndLine: rightEndLine, isInconsistentClone: isInconsistentClone}, $ijData);
  }
  soy.assertParamType(typeof leftProject === 'string', 'leftProject', leftProject, '@param', 'string');
  soy.assertParamType(typeof leftUniformPath === 'string', 'leftUniformPath', leftUniformPath, '@param', 'string');
  soy.assertParamType(leftCommit == null || google.isObject(leftCommit), 'leftCommit', leftCommit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  soy.assertParamType(leftStartLine == null || typeof leftStartLine === 'number', 'leftStartLine', leftStartLine, '@param', 'null|number|undefined');
  soy.assertParamType(leftEndLine == null || typeof leftEndLine === 'number', 'leftEndLine', leftEndLine, '@param', 'null|number|undefined');
  soy.assertParamType(typeof rightProject === 'string', 'rightProject', rightProject, '@param', 'string');
  soy.assertParamType(typeof rightUniformPath === 'string', 'rightUniformPath', rightUniformPath, '@param', 'string');
  soy.assertParamType(rightCommit == null || google.isObject(rightCommit), 'rightCommit', rightCommit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  soy.assertParamType(rightStartLine == null || typeof rightStartLine === 'number', 'rightStartLine', rightStartLine, '@param', 'null|number|undefined');
  soy.assertParamType(rightEndLine == null || typeof rightEndLine === 'number', 'rightEndLine', rightEndLine, '@param', 'null|number|undefined');
  soy.assertParamType(isInconsistentClone == null || typeof isInconsistentClone === 'boolean', 'isInconsistentClone', isInconsistentClone, '@param', 'boolean|null|undefined');
  let $output = '' + $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'compare', 'code') + $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'left-project', leftProject, true) + $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'right-project', rightProject) + $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'left-path', leftUniformPath) + $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'right-path', rightUniformPath);
  let leftFormattedCommit__soy13471 = '' + $commitForQueryValue$(soy.$$internalCallMarkerDoNotUse, $ijData, leftCommit);
  $output += $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'left-commit', leftFormattedCommit__soy13471);
  let rightFormattedCommit__soy13477 = '' + $commitForQueryValue$(soy.$$internalCallMarkerDoNotUse, $ijData, rightCommit);
  let $tmp;
  if (soy.$$coerceToBoolean(leftStartLine) && soy.$$coerceToBoolean(leftEndLine) && soy.$$coerceToBoolean(rightStartLine) && soy.$$coerceToBoolean(rightEndLine)) {
    $tmp = $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'region', '' + leftStartLine + '-' + leftEndLine + ':' + rightStartLine + '-' + rightEndLine);
  } else if (leftStartLine) {
    $tmp = $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'left-line', leftStartLine);
  } else if (rightStartLine) {
    $tmp = $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'right-line', rightStartLine);
  } else {
    $tmp = '';
  }
  $output += $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'right-commit', rightFormattedCommit__soy13477) + $tmp + (isInconsistentClone ? $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'inconsistent-clone', 'true') : '');
  return $output;
};
/**
 * @typedef {{
 *  leftProject: string,
 *  leftUniformPath: string,
 *  leftCommit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  leftStartLine?: (null|number|undefined),
 *  leftEndLine?: (null|number|undefined),
 *  rightProject: string,
 *  rightUniformPath: string,
 *  rightCommit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  rightStartLine?: (null|number|undefined),
 *  rightEndLine?: (null|number|undefined),
 *  isInconsistentClone?: (boolean|null|undefined),
 * }}
 */
$comparePerspective.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $comparePerspective.soyTemplateName = 'ts.commons.LinkTemplate.comparePerspective';
}


/**
 * @param {!$commitDetails.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $commitDetails = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.commitDetails']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.commitDetails'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} */
  const commit = soy.assertParamType(opt_data.commit == null || google.isObject(opt_data.commit), 'commit', opt_data.commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  /** @type {?} */
  const churn = opt_data.churn;
  return churn ? $uniformLink(soy.$$assignDefaults({perspective: 'activity', viewName: 'details', parameters: {churn: churn}}, opt_data), $ijData) : $uniformLink(soy.$$assignDefaults({perspective: 'activity', viewName: 'details'}, opt_data), $ijData);
};
export { $commitDetails as commitDetails };
/**
 * @typedef {{
 *  project: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  churn?: (?|undefined),
 *  uniformPath?: (null|string|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 *  parameters?: (?|undefined),
 * }}
 */
$commitDetails.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $commitDetails.soyTemplateName = 'ts.commons.LinkTemplate.commitDetails';
}


/**
 * @param {!$showUser.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $showUser = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $showUser$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.username, opt_data.isAdmin);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} username
 * @param {boolean|null|undefined=} isAdmin
 * @return {string}
 * @suppress {checkTypes}
 */
const $showUser$ = function($$areYouAnInternalCaller, $ijData, username, isAdmin) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showUser']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showUser']({username: username, isAdmin: isAdmin}, $ijData);
  }
  soy.assertParamType(typeof username === 'string', 'username', username, '@param', 'string');
  soy.assertParamType(isAdmin == null || typeof isAdmin === 'boolean', 'isAdmin', isAdmin, '@param', 'boolean|null|undefined');
  return isAdmin ? $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'admin', 'users', undefined, undefined, undefined, {action: 'edit', username: username}) : $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'user', 'show', undefined, undefined, undefined, {username: username});
};
export { $showUser as showUser };
export { $showUser$ as showUser$ };
/**
 * @typedef {{
 *  username: string,
 *  isAdmin?: (boolean|null|undefined),
 * }}
 */
$showUser.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showUser.soyTemplateName = 'ts.commons.LinkTemplate.showUser';
}


/**
 * @param {!$logView.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $logView = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $logView$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.viewName, opt_data.project, opt_data.level, opt_data.searchDetailedLogs, opt_data.filterRegex);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} viewName
 * @param {string} project
 * @param {null|string|undefined=} level
 * @param {null|string|undefined=} searchDetailedLogs
 * @param {null|string|undefined=} filterRegex
 * @return {string}
 * @suppress {checkTypes}
 */
const $logView$ = function($$areYouAnInternalCaller, $ijData, viewName, project, level, searchDetailedLogs, filterRegex) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.logView']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.logView']({viewName: viewName, project: project, level: level, searchDetailedLogs: searchDetailedLogs, filterRegex: filterRegex}, $ijData);
  }
  soy.assertParamType(typeof viewName === 'string', 'viewName', viewName, '@param', 'string');
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(level == null || typeof level === 'string', 'level', level, '@param', 'null|string|undefined');
  soy.assertParamType(searchDetailedLogs == null || typeof searchDetailedLogs === 'string', 'searchDetailedLogs', searchDetailedLogs, '@param', 'null|string|undefined');
  soy.assertParamType(filterRegex == null || typeof filterRegex === 'string', 'filterRegex', filterRegex, '@param', 'null|string|undefined');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'system', viewName, undefined, undefined, undefined, {project: project, level: level, searchDetailedLogs: searchDetailedLogs, filterRegex: filterRegex});
};
export { $logView as logView };
export { $logView$ as logView$ };
/**
 * @typedef {{
 *  viewName: string,
 *  project: string,
 *  level?: (null|string|undefined),
 *  searchDetailedLogs?: (null|string|undefined),
 *  filterRegex?: (null|string|undefined),
 * }}
 */
$logView.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $logView.soyTemplateName = 'ts.commons.LinkTemplate.logView';
}


/**
 * @param {!$showCommitTreeDetails.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $showCommitTreeDetails = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $showCommitTreeDetails$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.projectId, opt_data.repositoryId);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} projectId
 * @param {string} repositoryId
 * @return {string}
 * @suppress {checkTypes}
 */
const $showCommitTreeDetails$ = function($$areYouAnInternalCaller, $ijData, projectId, repositoryId) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showCommitTreeDetails']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showCommitTreeDetails']({projectId: projectId, repositoryId: repositoryId}, $ijData);
  }
  soy.assertParamType(typeof projectId === 'string', 'projectId', projectId, '@param', 'string');
  soy.assertParamType(typeof repositoryId === 'string', 'repositoryId', repositoryId, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'project', undefined, undefined, undefined, {name: projectId, action: 'showCommitTreeDetails'}) + $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'repository-id', repositoryId);
};
export { $showCommitTreeDetails as showCommitTreeDetails };
export { $showCommitTreeDetails$ as showCommitTreeDetails$ };
/**
 * @typedef {{
 *  projectId: string,
 *  repositoryId: string,
 * }}
 */
$showCommitTreeDetails.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showCommitTreeDetails.soyTemplateName = 'ts.commons.LinkTemplate.showCommitTreeDetails';
}


/**
 * @param {!$showAnalysisProfile.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $showAnalysisProfile = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $showAnalysisProfile$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.analysisProfileName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} analysisProfileName
 * @return {string}
 * @suppress {checkTypes}
 */
const $showAnalysisProfile$ = function($$areYouAnInternalCaller, $ijData, analysisProfileName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showAnalysisProfile']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showAnalysisProfile']({analysisProfileName: analysisProfileName}, $ijData);
  }
  soy.assertParamType(typeof analysisProfileName === 'string', 'analysisProfileName', analysisProfileName, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'analysis-profiles', undefined, undefined, undefined, {name: analysisProfileName, action: 'profile-check-explorer'});
};
export { $showAnalysisProfile as showAnalysisProfile };
export { $showAnalysisProfile$ as showAnalysisProfile$ };
/**
 * @typedef {{
 *  analysisProfileName: string,
 * }}
 */
$showAnalysisProfile.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showAnalysisProfile.soyTemplateName = 'ts.commons.LinkTemplate.showAnalysisProfile';
}


/**
 * @param {!$showAnalysisProfileHistory.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $showAnalysisProfileHistory = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $showAnalysisProfileHistory$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.analysisProfileName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} analysisProfileName
 * @return {string}
 * @suppress {checkTypes}
 */
const $showAnalysisProfileHistory$ = function($$areYouAnInternalCaller, $ijData, analysisProfileName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showAnalysisProfileHistory']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showAnalysisProfileHistory']({analysisProfileName: analysisProfileName}, $ijData);
  }
  soy.assertParamType(typeof analysisProfileName === 'string', 'analysisProfileName', analysisProfileName, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'analysis-profiles', undefined, undefined, undefined, {name: analysisProfileName, action: 'history'});
};
export { $showAnalysisProfileHistory as showAnalysisProfileHistory };
export { $showAnalysisProfileHistory$ as showAnalysisProfileHistory$ };
/**
 * @typedef {{
 *  analysisProfileName: string,
 * }}
 */
$showAnalysisProfileHistory.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showAnalysisProfileHistory.soyTemplateName = 'ts.commons.LinkTemplate.showAnalysisProfileHistory';
}


/**
 * @param {!$printQualityReport.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedUri}
 */
const $printQualityReport = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $printQualityReport$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.qualityReportId);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} qualityReportId
 * @return {!goog_soy_data.SanitizedUri}
 * @suppress {checkTypes}
 */
const $printQualityReport$ = function($$areYouAnInternalCaller, $ijData, qualityReportId) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.printQualityReport']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.printQualityReport']({qualityReportId: qualityReportId}, $ijData);
  }
  soy.assertParamType(typeof qualityReportId === 'string', 'qualityReportId', qualityReportId, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedUri(soy.$$filterNormalizeUri($uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'qualitycontrol', 'reports', undefined, undefined, undefined, {id: qualityReportId, action: 'print'})));
};
export { $printQualityReport as printQualityReport };
export { $printQualityReport$ as printQualityReport$ };
/**
 * @typedef {{
 *  qualityReportId: string,
 * }}
 */
$printQualityReport.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $printQualityReport.soyTemplateName = 'ts.commons.LinkTemplate.printQualityReport';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $listMetricThresholdConfiguration = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.listMetricThresholdConfiguration']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.listMetricThresholdConfiguration'](opt_data, $ijData);
  }
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'metric-threshold-configuration');
};
export { $listMetricThresholdConfiguration as listMetricThresholdConfiguration };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $listMetricThresholdConfiguration.soyTemplateName = 'ts.commons.LinkTemplate.listMetricThresholdConfiguration';
}


/**
 * @param {!$showMetricThresholdConfiguration.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $showMetricThresholdConfiguration = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $showMetricThresholdConfiguration$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.metricThresholdConfigurationName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} metricThresholdConfigurationName
 * @return {string}
 * @suppress {checkTypes}
 */
const $showMetricThresholdConfiguration$ = function($$areYouAnInternalCaller, $ijData, metricThresholdConfigurationName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showMetricThresholdConfiguration']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showMetricThresholdConfiguration']({metricThresholdConfigurationName: metricThresholdConfigurationName}, $ijData);
  }
  soy.assertParamType(typeof metricThresholdConfigurationName === 'string', 'metricThresholdConfigurationName', metricThresholdConfigurationName, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'metric-threshold-configuration', undefined, undefined, undefined, {name: metricThresholdConfigurationName});
};
export { $showMetricThresholdConfiguration as showMetricThresholdConfiguration };
export { $showMetricThresholdConfiguration$ as showMetricThresholdConfiguration$ };
/**
 * @typedef {{
 *  metricThresholdConfigurationName: string,
 * }}
 */
$showMetricThresholdConfiguration.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showMetricThresholdConfiguration.soyTemplateName = 'ts.commons.LinkTemplate.showMetricThresholdConfiguration';
}


/**
 * @param {!$editExternalCredentialsRolesLink.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $editExternalCredentialsRolesLink = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $editExternalCredentialsRolesLink$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.credentialsName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} credentialsName
 * @return {string}
 * @suppress {checkTypes}
 */
const $editExternalCredentialsRolesLink$ = function($$areYouAnInternalCaller, $ijData, credentialsName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.editExternalCredentialsRolesLink']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.editExternalCredentialsRolesLink']({credentialsName: credentialsName}, $ijData);
  }
  soy.assertParamType(typeof credentialsName === 'string', 'credentialsName', credentialsName, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'admin', 'external-accounts', undefined, undefined, undefined, {name: credentialsName, action: 'roles'});
};
export { $editExternalCredentialsRolesLink as editExternalCredentialsRolesLink };
export { $editExternalCredentialsRolesLink$ as editExternalCredentialsRolesLink$ };
/**
 * @typedef {{
 *  credentialsName: string,
 * }}
 */
$editExternalCredentialsRolesLink.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $editExternalCredentialsRolesLink.soyTemplateName = 'ts.commons.LinkTemplate.editExternalCredentialsRolesLink';
}


/**
 * @param {!$issue.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $issue = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $issue$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.id, opt_data.selectedTestGapBranch);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {?} id
 * @param {?=} selectedTestGapBranch
 * @return {string}
 * @suppress {checkTypes}
 */
const $issue$ = function($$areYouAnInternalCaller, $ijData, project, id, selectedTestGapBranch) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.issue']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.issue']({project: project, id: id, selectedTestGapBranch: selectedTestGapBranch}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  let $output = '';
  const issueId__soy13575 = id.internalId ? id.internalId : id;
  $output += $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'activity', 'issues', project, undefined, id.internalId, selectedTestGapBranch ? {issueBranch: selectedTestGapBranch, action: 'view', id: issueId__soy13575} : {action: 'view', id: issueId__soy13575});
  return $output;
};
export { $issue as issue };
export { $issue$ as issue$ };
/**
 * @typedef {{
 *  project: string,
 *  id: ?,
 *  selectedTestGapBranch?: (?|undefined),
 * }}
 */
$issue.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $issue.soyTemplateName = 'ts.commons.LinkTemplate.issue';
}


/**
 * @param {!$specItem.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $specItem = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $specItem$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.id, opt_data.tracingTab, opt_data.commit);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {?} id
 * @param {null|number|undefined=} tracingTab
 * @param {?=} commit
 * @return {string}
 * @suppress {checkTypes}
 */
const $specItem$ = function($$areYouAnInternalCaller, $ijData, project, id, tracingTab, commit) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.specItem']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.specItem']({project: project, id: id, tracingTab: tracingTab, commit: commit}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(tracingTab == null || typeof tracingTab === 'number', 'tracingTab', tracingTab, '@param', 'null|number|undefined');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'requirements-tracing', 'details', project, commit, undefined, {id: id.internalId ? id.internalId : id, tracingTab: tracingTab});
};
export { $specItem as specItem };
export { $specItem$ as specItem$ };
/**
 * @typedef {{
 *  project: string,
 *  id: ?,
 *  tracingTab?: (null|number|undefined),
 *  commit?: (?|undefined),
 * }}
 */
$specItem.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $specItem.soyTemplateName = 'ts.commons.LinkTemplate.specItem';
}


/**
 * @param {!$testQuery.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @private
 */
const $testQuery = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $testQuery$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.query, opt_data.queryName, opt_data.commit);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {string} query
 * @param {null|string|undefined=} queryName
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @return {string}
 * @private
 * @suppress {checkTypes}
 */
const $testQuery$ = function($$areYouAnInternalCaller, $ijData, project, query, queryName, commit) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.testQuery']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.testQuery']({project: project, query: query, queryName: queryName, commit: commit}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(typeof query === 'string', 'query', query, '@param', 'string');
  soy.assertParamType(queryName == null || typeof queryName === 'string', 'queryName', queryName, '@param', 'null|string|undefined');
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'metrics', 'tests', project, commit, undefined, {action: 'filter', q: query, queryname: queryName != null ? queryName : ''});
};
/**
 * @typedef {{
 *  project: string,
 *  query: string,
 *  queryName?: (null|string|undefined),
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 * }}
 */
$testQuery.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $testQuery.soyTemplateName = 'ts.commons.LinkTemplate.testQuery';
}


/**
 * @param {!$taskDetails.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $taskDetails = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $taskDetails$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.id);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {number} id
 * @return {string}
 * @suppress {checkTypes}
 */
const $taskDetails$ = function($$areYouAnInternalCaller, $ijData, project, id) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.taskDetails']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.taskDetails']({project: project, id: id}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(typeof id === 'number', 'id', id, '@param', 'number');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'qualitycontrol', 'tasks', project, undefined, undefined, {id: id, action: 'details'});
};
export { $taskDetails as taskDetails };
export { $taskDetails$ as taskDetails$ };
/**
 * @typedef {{
 *  project: string,
 *  id: number,
 * }}
 */
$taskDetails.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $taskDetails.soyTemplateName = 'ts.commons.LinkTemplate.taskDetails';
}


/**
 * @param {!$uniformLink.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $uniformLink = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.perspective, opt_data.viewName, opt_data.project, opt_data.commit, opt_data.uniformPath, opt_data.parameters, opt_data.partitions);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} perspective
 * @param {string} viewName
 * @param {null|string|undefined=} project
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @param {null|string|undefined=} uniformPath
 * @param {?=} parameters
 * @param {!Array<string>|null|undefined=} partitions
 * @return {string}
 * @suppress {checkTypes}
 */
const $uniformLink$ = function($$areYouAnInternalCaller, $ijData, perspective, viewName, project, commit, uniformPath, parameters, partitions) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.uniformLink']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.uniformLink']({perspective: perspective, viewName: viewName, project: project, commit: commit, uniformPath: uniformPath, parameters: parameters, partitions: partitions}, $ijData);
  }
  soy.assertParamType(typeof perspective === 'string', 'perspective', perspective, '@param', 'string');
  soy.assertParamType(typeof viewName === 'string', 'viewName', viewName, '@param', 'string');
  soy.assertParamType(project == null || typeof project === 'string', 'project', project, '@param', 'null|string|undefined');
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  soy.assertParamType(uniformPath == null || typeof uniformPath === 'string', 'uniformPath', uniformPath, '@param', 'null|string|undefined');
  soy.assertParamType(partitions == null || Array.isArray(partitions), 'partitions', partitions, '@param', '!Array<string>|null|undefined');
  let $output = soy.$$escapeUri(perspective) + '/' + (viewName ? soy.$$escapeUri(viewName) : '') + (project ? '/' + soy.$$escapeUri(project) : '') + (uniformPath ? '/' + soy.$$escapeUri(uniformPath) : '');
  if (parameters) {
    const key13623List = soy.$$getMapKeys(parameters);
    const key13623ListLen = key13623List.length;
    for (let key13623Index = 0; key13623Index < key13623ListLen; key13623Index++) {
      const key13623Data = key13623List[key13623Index];
      $output += $query$(soy.$$internalCallMarkerDoNotUse, $ijData, key13623Data, parameters[/** @type {?} */ (key13623Data)], key13623Index == 0);
    }
  }
  $output += commit != null ? $queryWithCommit$(soy.$$internalCallMarkerDoNotUse, $ijData, parameters, commit) : '';
  if (partitions) {
    const partition13636List = partitions;
    const partition13636ListLen = partition13636List.length;
    if (partition13636ListLen > 0) {
      for (let partition13636Index = 0; partition13636Index < partition13636ListLen; partition13636Index++) {
        const partition13636Data = partition13636List[partition13636Index];
        $output += $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'partition', partition13636Data, (!parameters || parameters.length == 0) && commit == null && partition13636Index == 0);
      }
    } else {
      $output += $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'partition-empty', 'true', (!parameters || parameters.length == 0) && commit == null);
    }
  }
  return $output;
};
export { $uniformLink as uniformLink };
export { $uniformLink$ as uniformLink$ };
/**
 * @typedef {{
 *  perspective: string,
 *  viewName: string,
 *  project?: (null|string|undefined),
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  uniformPath?: (null|string|undefined),
 *  parameters?: (?|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$uniformLink.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $uniformLink.soyTemplateName = 'ts.commons.LinkTemplate.uniformLink';
}


/**
 * @param {?$commitForQueryValue.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @private
 */
const $commitForQueryValue = function(opt_data, opt_ijData) {
  opt_data = opt_data || {};
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $commitForQueryValue$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.commit);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @return {string}
 * @private
 * @suppress {checkTypes}
 */
const $commitForQueryValue$ = function($$areYouAnInternalCaller, $ijData, commit) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.commitForQueryValue']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.commitForQueryValue']({commit: commit}, $ijData);
  }
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  let $output = '';
  if (commit != null) {
    const branchName__soy13650 = commit.branchName;
    const timestamp__soy13651 = commit.timestamp;
    const previous__soy13652 = commit.previous;
    $output += (branchName__soy13650 != null ? '' + branchName__soy13650 + ':' : '') + (timestamp__soy13651 != null ? '' + timestamp__soy13651 : 'HEAD') + (previous__soy13652 != null && previous__soy13652 > 0 ? 'p' + '' + previous__soy13652 : '');
  } else {
    $output += 'HEAD';
  }
  return $output;
};
/**
 * @typedef {{
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 * }}
 */
$commitForQueryValue.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $commitForQueryValue.soyTemplateName = 'ts.commons.LinkTemplate.commitForQueryValue';
}


/**
 * @param {!$queryWithCommit.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @private
 */
const $queryWithCommit = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $queryWithCommit$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.parameters, opt_data.commit);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} parameters
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @return {string}
 * @private
 * @suppress {checkTypes}
 */
const $queryWithCommit$ = function($$areYouAnInternalCaller, $ijData, parameters, commit) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.queryWithCommit']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.queryWithCommit']({commit: commit, parameters: parameters}, $ijData);
  }
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  let $output = '';
  let formattedCommit__soy13669 = '' + $commitForQueryValue$(soy.$$internalCallMarkerDoNotUse, $ijData, commit);
  $output += $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 't', formattedCommit__soy13669, !parameters || parameters.length == 0);
  return $output;
};
/**
 * @typedef {{
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  parameters: ?,
 * }}
 */
$queryWithCommit.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $queryWithCommit.soyTemplateName = 'ts.commons.LinkTemplate.queryWithCommit';
}


/**
 * @param {!$query.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @private
 */
const $query = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $query$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.parameter, opt_data.value, opt_data.isFirst);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} parameter
 * @param {boolean|null|number|string|undefined=} value
 * @param {boolean|null|undefined=} isFirst
 * @return {string}
 * @private
 * @suppress {checkTypes}
 */
const $query$ = function($$areYouAnInternalCaller, $ijData, parameter, value, isFirst) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.query']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.query']({parameter: parameter, value: value, isFirst: isFirst}, $ijData);
  }
  soy.assertParamType(typeof parameter === 'string', 'parameter', parameter, '@param', 'string');
  soy.assertParamType(value == null || typeof value === 'boolean' || typeof value === 'number' || typeof value === 'string', 'value', value, '@param', 'boolean|null|number|string|undefined');
  soy.assertParamType(isFirst == null || typeof isFirst === 'boolean', 'isFirst', isFirst, '@param', 'boolean|null|undefined');
  let $tmp;
  if (isFirst) {
    $tmp = '?';
  } else if (value != null) {
    $tmp = '&';
  } else {
    $tmp = '';
  }
  let $output = '' + $tmp;
  let queryString__soy13682 = '' + (value != null ? soy.$$escapeUri(parameter) + '=' + soy.$$escapeUri(value) : '');
  $output += '' + queryString__soy13682;
  return $output;
};
/**
 * @typedef {{
 *  parameter: string,
 *  value?: (boolean|null|number|string|undefined),
 *  isFirst?: (boolean|null|undefined),
 * }}
 */
$query.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $query.soyTemplateName = 'ts.commons.LinkTemplate.query';
}


/**
 * @param {!$architectureEditor.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $architectureEditor = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $architectureEditor$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.assessmentIdentifier, opt_data.commit, opt_data.component);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {string} assessmentIdentifier
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @param {?=} component
 * @return {string}
 * @suppress {checkTypes}
 */
const $architectureEditor$ = function($$areYouAnInternalCaller, $ijData, project, assessmentIdentifier, commit, component) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.architectureEditor']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.architectureEditor']({project: project, assessmentIdentifier: assessmentIdentifier, commit: commit, component: component}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(typeof assessmentIdentifier === 'string', 'assessmentIdentifier', assessmentIdentifier, '@param', 'string');
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'architecture', 'editor', project, commit, assessmentIdentifier, component ? {component: component} : []);
};
export { $architectureEditor as architectureEditor };
export { $architectureEditor$ as architectureEditor$ };
/**
 * @typedef {{
 *  project: string,
 *  assessmentIdentifier: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  component?: (?|undefined),
 * }}
 */
$architectureEditor.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $architectureEditor.soyTemplateName = 'ts.commons.LinkTemplate.architectureEditor';
}


/**
 * @param {!$file.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $file = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.file']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.file'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {string} */
  const uniformPath = soy.assertParamType(typeof opt_data.uniformPath === 'string', 'uniformPath', opt_data.uniformPath, '@param', 'string');
  /** @type {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} */
  const commit = soy.assertParamType(opt_data.commit == null || google.isObject(opt_data.commit), 'commit', opt_data.commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  /** @type {?} */
  const visibleMetric = opt_data.visibleMetric;
  /** @type {null|string|undefined} */
  const targetPerspectiveName = soy.assertParamType(opt_data.targetPerspectiveName == null || typeof opt_data.targetPerspectiveName === 'string', 'targetPerspectiveName', opt_data.targetPerspectiveName, '@param', 'null|string|undefined');
  /** @type {null|string|undefined} */
  const targetViewName = soy.assertParamType(opt_data.targetViewName == null || typeof opt_data.targetViewName === 'string', 'targetViewName', opt_data.targetViewName, '@param', 'null|string|undefined');
  return soy.$$checkNotNull(uniformPath).endsWith('.architecture') ? $architectureEditor$(soy.$$internalCallMarkerDoNotUse, $ijData, project, uniformPath, commit) : $code(/** @type {?} */ (opt_data), $ijData);
};
export { $file as file };
/**
 * @typedef {{
 *  project: string,
 *  uniformPath: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  visibleMetric?: (?|undefined),
 *  targetPerspectiveName?: (null|string|undefined),
 *  targetViewName?: (null|string|undefined),
 *  findingFilter?: (!Array<string>|null|undefined),
 *  selection?: (?|undefined),
 *  identifier?: (null|string|undefined),
 *  highlight?: (null|string|undefined),
 *  thresholdProfile?: (null|string|undefined),
 *  highlightMetric?: (boolean|null|undefined),
 *  showCoverage?: (boolean|null|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$file.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $file.soyTemplateName = 'ts.commons.LinkTemplate.file';
}


/**
 * @param {!$metricsShowingPerspective.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $metricsShowingPerspective = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.metricsShowingPerspective']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.metricsShowingPerspective'](opt_data, $ijData);
  }
  /** @type {string} */
  const uniformPath = soy.assertParamType(typeof opt_data.uniformPath === 'string', 'uniformPath', opt_data.uniformPath, '@param', 'string');
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} */
  const commit = soy.assertParamType(opt_data.commit == null || google.isObject(opt_data.commit), 'commit', opt_data.commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  /** @type {null|string|undefined} */
  const resourceType = soy.assertParamType(opt_data.resourceType == null || typeof opt_data.resourceType === 'string', 'resourceType', opt_data.resourceType, '@param', 'null|string|undefined');
  /** @type {null|string|undefined} */
  const visibleMetric = soy.assertParamType(opt_data.visibleMetric == null || typeof opt_data.visibleMetric === 'string', 'visibleMetric', opt_data.visibleMetric, '@param', 'null|string|undefined');
  /** @type {null|string|undefined} */
  const targetPerspectiveName = soy.assertParamType(opt_data.targetPerspectiveName == null || typeof opt_data.targetPerspectiveName === 'string', 'targetPerspectiveName', opt_data.targetPerspectiveName, '@param', 'null|string|undefined');
  /** @type {!Array<string>|null|undefined} */
  const partitions = soy.assertParamType(opt_data.partitions == null || Array.isArray(opt_data.partitions), 'partitions', opt_data.partitions, '@param', '!Array<string>|null|undefined');
  let $tmp;
  if (soy.$$strContains('' + uniformPath, '.architecture') && !soy.$$equals('FILE', resourceType)) {
    $tmp = $architectureMetrics(/** @type {?} */ (opt_data), $ijData);
  } else if (soy.$$checkNotNull(uniformPath).startsWith('-issues-/') && soy.$$equals('FILE', resourceType)) {
    $tmp = $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'activity', 'issues', project, undefined, undefined, {queryname: uniformPath});
  } else if (soy.$$checkNotNull(uniformPath).startsWith('-spec-items-') && soy.$$equals('FILE', resourceType)) {
    $tmp = $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'requirements-tracing', 'overview', project, undefined, undefined, {queryname: uniformPath, specItemTab: 0});
  } else if (soy.$$checkNotNull(uniformPath).startsWith('-test-implementation-/') || soy.$$checkNotNull(uniformPath).startsWith('-test-execution-/')) {
    $tmp = soy.$$equals('FILE', resourceType) ? $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'metrics', 'tests', project, undefined, uniformPath, {details: true}) : $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'metrics', 'tests', project, undefined, uniformPath, undefined, partitions);
  } else if (soy.$$checkNotNull(uniformPath).startsWith('-test-query-/')) {
    $tmp = $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'metrics', 'tests', project, undefined, undefined, {action: 'filter', queryname: ('' + uniformPath).substring(13)}, partitions);
  } else {
    $tmp = visibleMetric ? $file(soy.$$assignDefaults({highlightMetric: true}, opt_data), $ijData) : $file(/** @type {?} */ (opt_data), $ijData);
  }
  return $tmp;
};
export { $metricsShowingPerspective as metricsShowingPerspective };
/**
 * @typedef {{
 *  uniformPath: string,
 *  project: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  resourceType?: (null|string|undefined),
 *  visibleMetric?: (null|string|undefined),
 *  targetPerspectiveName?: (null|string|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 *  thresholdProfile?: (null|string|undefined),
 *  targetViewName?: (null|string|undefined),
 *  findingFilter?: (!Array<string>|null|undefined),
 *  selection?: (?|undefined),
 *  identifier?: (null|string|undefined),
 *  highlight?: (null|string|undefined),
 *  showCoverage?: (boolean|null|undefined),
 *  highlightMetric?: (boolean|null|undefined),
 * }}
 */
$metricsShowingPerspective.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $metricsShowingPerspective.soyTemplateName = 'ts.commons.LinkTemplate.metricsShowingPerspective';
}
