// Used in subclasses
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ServiceCallError } from 'api/ServiceCallError';
import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import type { Root } from 'react-dom/client';
import type { FallbackProps } from 'react-error-boundary';
import type { JSX } from 'react/jsx-runtime';
import * as WidgetParameterTemplate from 'soy/perspectives/dashboard/widgets/parameters/WidgetParameterTemplate.soy.generated';
import * as dom from 'ts-closure-library/lib/dom/dom';
import { Size } from 'ts-closure-library/lib/math/size';
import { ReactUtils } from 'ts/base/ReactUtils';
import * as soy from 'ts/base/soy/SoyRenderer';
import { SuspendingErrorBoundary } from 'ts/base/SuspendingErrorBoundary';
import { ReactDisposable } from 'ts/base/view/ReactDisposable';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import { Assertions } from 'ts/commons/Assertions';
import type { MetricGroup } from 'ts/commons/dialog/HierarchicMetricThresholdSelectionDialog';
import { MetricSchemaUtils } from 'ts/commons/MetricSchemaUtils';
import { ProjectAndUniformPath } from 'ts/commons/ProjectAndUniformPath';
import { TimeContext } from 'ts/commons/time/TimeContext';
import { TimeUtils } from 'ts/commons/time/TimeUtils';
import type { TypedPointInTime } from 'ts/commons/time/TypedPointInTime';
import { tsdom } from 'ts/commons/tsdom';
import { UniformPath } from 'ts/commons/UniformPath';
import { Icon } from 'ts/components/Icon';
import { Message, MessageHeader } from 'ts/components/Message';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';
import type { ExtendedProjectInfo } from 'ts/data/ExtendedProjectInfo';
import { NoWidgetDataAvailable } from 'ts/perspectives/dashboard/widgets/common/NoWidgetDataAvailable';
import {
	WidgetEditMenu,
	WidgetMenuContext,
	type WidgetMenuContextValues,
	WidgetQuickEditMenu
} from 'ts/perspectives/dashboard/widgets/common/WidgetEditMenu';
import type { UniformPathLikeWithLabelAndMetric } from 'ts/perspectives/dashboard/widgets/parameters/ProjectsPathsParameter';
import type { WidgetDescriptor } from 'ts/perspectives/dashboard/widgets/WidgetFactory';
import type { MetricDirectorySchema } from 'typedefs/MetricDirectorySchema';
import { WidgetTitle } from './common/WidgetTitle';
import { BooleanParameter } from './parameters/BooleanParameter';
import type { ParameterLookup } from './parameters/ParameterLookup';
import type { ProjectPathParameterValue } from './parameters/ProjectPathParameter';
import { ProjectPathParameter } from './parameters/ProjectPathParameter';
import type { SingleMetricParameter } from './parameters/SingleMetricParameter';
import { TitleParameter } from './parameters/TitleParameter';
import type { WidgetParameterBase } from './parameters/WidgetParameterBase';
import type { WidgetEditingContext } from './WidgetEditingContext';
import { WidgetUtils } from './WidgetUtils';

/** A type for the parameter values of a widget. */
export type WidgetParameterValues =
	| ProjectPathParameterValue
	| string[]
	| Record<string, unknown>
	| number
	| string
	| boolean
	| MetricGroup[]
	| UniformPathLikeWithLabelAndMetric[];

/** Base class for dashboard widgets. */
export class WidgetBase extends ReactDisposable {
	/** Error state CSS class */
	public static ERROR_STATE_CSS_CLASS = 'error-state';

	/** The title parameter. */
	public static TITLE_PARAMETER: TitleParameter = new TitleParameter('Title', 'The title of the element');

	/** Parameter name of a parameter that refers to a plain project ID. */
	public static readonly PROJECT_PARAMETER_NAME = 'Project';

	/** Parameter name that stores multiple paths. */
	public static readonly PROJECT_PATHS_PARAMETER_NAME = 'Paths';

	/** Parameter name that stores multiple project and path combinations. */
	public static readonly STORED_ADDITIONAL_PATHS_PARAMETER_NAME = 'Additional paths';

	/** Parameter for both project and path. This is not used in this class, but many subclasses share this parameter. */
	public static PROJECT_PATH_PARAMETER = new ProjectPathParameter(
		'Path',
		'The project and path for which to display data.'
	);

	/** Parameter whether to abbreviate large metric values (if shown). */
	public static ABBREVIATE_VALUES_PARAMETER = new BooleanParameter(
		'Abbreviate values',
		'Whether to abbreviate large values.'
	);

	/** Parameter whether to abbreviate large metric values (if shown). */
	public static VALUE_IS_BYTES_PARAMETER = new BooleanParameter(
		'Value is bytes',
		'Whether the value should be interpreted as bytes. For abbreviation, this means that we divide by 1024 instead of 1000.'
	);

	/** Array of error messages. */
	private errors: string[] = [];

	/** Array of warnings. */
	private warnings: string[] = [];

	/** The project referenced by a widget instance. Changes during widget editing operations. */
	protected referencedProject: string | null = null;

	protected timeContext: TimeContext | null = null;

	/** Flag indicates whether trend setting parameter is ignored. */
	private trendIgnored = false;

	/** This widget's descriptor */
	protected descriptor: WidgetDescriptor | null = null;

	private widgetContext: WidgetEditingContext | null = null;

	private frameRoot?: Root;

	/** The content element into which the widget content should be appended. */
	protected get containerElement(): HTMLElement {
		return Assertions.assertIsHtmlElement(
			this.containerElementInternal!,
			'Element may not be used during initialization.'
		);
	}

	/**
	 * @param containerElement The element to render the widget into.
	 * @param perspectiveContext The current (extended) perspective context
	 */
	public constructor(
		protected containerElementInternal: HTMLElement | null,
		protected perspectiveContext: ExtendedPerspectiveContext
	) {
		super();
	}

	/** Sets this widget's descriptor and context. This has to be done before calling {@link render} */
	public setDescriptorAndContext(descriptor: WidgetDescriptor | null, context: WidgetEditingContext): void {
		this.descriptor = descriptor;
		this.widgetContext = context;
	}

	protected override getPerspectiveContext(): ExtendedPerspectiveContext {
		return this.perspectiveContext;
	}

	/** Returns whether errors occurred. */
	public errorsOccurred(): boolean {
		return !ArrayUtils.isEmpty(this.errors);
	}

	/** Returns the parameters supported by this widget. */
	public getParameters(): WidgetParameterBase[] {
		return [WidgetBase.TITLE_PARAMETER];
	}

	/** Returns a default value for the given parameter. */
	public provideParameterDefault(
		parameter: WidgetParameterBase,
		context: WidgetEditingContext
	): WidgetParameterValues {
		if (parameter === WidgetBase.TITLE_PARAMETER) {
			return WidgetUtils.DEFAULT_WIDGET_TITLE;
		}

		// This is not used in this class, but many sub-classes share this
		// parameter.
		if (parameter === WidgetBase.PROJECT_PATH_PARAMETER) {
			return this.getDefaultProjectAndPath(context);
		}
		if (parameter === WidgetBase.ABBREVIATE_VALUES_PARAMETER) {
			return true;
		}
		if (parameter === WidgetBase.VALUE_IS_BYTES_PARAMETER) {
			return false;
		}
		Assertions.fail('No default value implemented for parameter ' + parameter.getName() + '!');
	}

	/** Returns the default project and path to use. */
	protected getDefaultProjectAndPath(context: WidgetEditingContext): ProjectPathParameterValue {
		let project = 'unknown';
		if (context.preferredProject) {
			project = context.preferredProject;
		} else if (context.projects[0]) {
			project = context.projects[0].primaryId!;
		}
		return {
			project,
			path: '',
			hiddenInWidgetTitle: false,
			isArchitecture: false
		};
	}

	/** Loads the pre-loaded CODE metric schema for the given project and type. */
	protected getCodeMetricsSchema(projectId: string): MetricDirectorySchema {
		return this.widgetContext!.getCodeMetricsSchema(projectId);
	}

	/**
	 * Loads the (potentially pre-preloaded) metric schema for the given project and type. For the default 'CODE'
	 * metrics schema, use {@link #getCodeMetricsSchema} instead.
	 */
	protected async getMetricSchema(projectId: string, path: string): Promise<MetricDirectorySchema> {
		const type = new UniformPath(path).type;
		return this.widgetContext!.getMetricsSchema(projectId, type.name);
	}

	/**
	 * In order to correctly inform a user why data is not available for a widget to display graphical output, this
	 * function determines if its because of on-going (re-)analysis of this widget's referenced project or even all
	 * projects.
	 */
	protected notifyDataUnavailable(): void {
		this.appendComponent(<NoWidgetDataAvailable projectId={this.referencedProject} />, this.containerElement);
	}

	/** Show a simple message as widget content. */
	public showMessage(message: string): void {
		this.appendComponent(
			<Message info className="h-full max-w-full text-center content-center">
				{message}
			</Message>,
			this.containerElement
		);
	}

	/** Renders the quick edit menu. Can be overriden to add additional action buttons for a widget. */
	protected renderQuickEditMenu(_parameterLookup: ParameterLookup) {
		return <WidgetQuickEditMenu />;
	}

	/** Renders the title of the widget. */
	protected renderTitle(
		parameterLookup: ParameterLookup,
		commit: UnresolvedCommitDescriptor | undefined
	): JSX.Element | null {
		return (
			<WidgetTitle
				projectAndPath={parameterLookup('Path') as ProjectPathParameterValue}
				title={parameterLookup('Title') as string}
				commit={commit}
				warnings={this.warnings}
			/>
		);
	}

	/** Renders the element into the container element. */
	public async render(
		parameterLookup: ParameterLookup,
		commit: UnresolvedCommitDescriptor | null,
		menuContext: WidgetMenuContextValues
	): Promise<void> {
		this.referencedProject = this.getProject(parameterLookup);
		if (this.referencedProject !== null) {
			this.checkForProjectReferencedByInternalId(this.referencedProject);
			if (this.errorsOccurred()) {
				this.refreshContent(parameterLookup, commit, menuContext);
				return;
			}
		}
		this.timeContext = new TimeContext(commit);
		this.removeContent();

		const analysisInProgress =
			this.referencedProject !== null &&
			this.perspectiveContext.projectsInfo.initialProjects.includes(this.referencedProject);
		if (analysisInProgress) {
			this.renderContentViaReact(
				parameterLookup,
				commit,
				menuContext,
				<NoWidgetDataAvailable projectId={this.referencedProject} />
			);
			return;
		}
		this.renderContentViaReact(parameterLookup, commit, menuContext, <Loading />);
		this.warnings = [];
		this.errors = [];
		await this.preloadDataAsync(parameterLookup).catch(error => this.handleLoadingError(error));
		this.refreshContent(parameterLookup, commit, menuContext);
	}

	/**
	 * Renders the content of the widget frame (title, buttons) and the given React element into the widget-content
	 * element if not null.
	 */
	private renderContentViaReact(
		parameterLookup: ParameterLookup,
		commit: UnresolvedCommitDescriptor | null,
		menuContext: WidgetMenuContextValues,
		content: JSX.Element | null
	) {
		if (this.frameRoot === undefined) {
			this.frameRoot = ReactUtils.prependRoot(this.containerElement.parentElement!);
		}
		const title = this.renderTitle(parameterLookup, commit ?? undefined);
		const menu = this.widgetContext!.isEditMode ? <WidgetEditMenu /> : this.renderQuickEditMenu(parameterLookup);
		const renderViaReact = content != null;
		ReactUtils.replace(
			this.wrapFrameComponent(
				<WidgetFrameContent
					menuContext={menuContext}
					title={title}
					menu={menu}
					contentElement={renderViaReact ? this.containerElement : null}
				>
					{content}
				</WidgetFrameContent>,
				parameterLookup
			),
			this.frameRoot
		);
	}

	/**
	 * Allows to wrap the whole widget frame element with additional context providers if needed to share state between
	 * the action buttons and the widget content.
	 */
	protected wrapFrameComponent(element: JSX.Element, parameterLookup: ParameterLookup): ReactNode {
		return element;
	}

	/** Returns the user-visible projects. */
	protected getProjects(): ExtendedProjectInfo[] {
		return this.widgetContext!.projects;
	}

	/**
	 * Helper method that returns the primary ids of all of {@link #getProjects}. Only available after {@link render} has
	 * been called.
	 */
	protected getPrimaryProjectIds(): string[] {
		return this.getProjects().map(projectInfo => projectInfo.primaryId);
	}

	/** Disposes the widget from the DOM. */
	public override dispose(): void {
		super.dispose();
		this.removeContent();
		ReactUtils.unmount(this.frameRoot);
	}

	/**
	 * Cleans up any side-effect from having the widget in the DOM that React has not introduced. In contrast to
	 * {@link #dispose} this is executed after every change of the widget, whereas {@link #dispose} is only called when
	 * navigating away from the view.
	 */
	protected removeContent(): void {
		super.dispose();
		tsdom.removeNonReactPortalChildren(this.containerElement);
	}

	/**
	 * Loads the data needed to render the widgets. Any errors that occur (rejected promises and thrown errors) will be
	 * caught by this class and lead to a widget with a error message. It is also possible to specifically handle errors
	 * on a per-request basis using {@link Promise#catch}.
	 */
	public async preloadDataAsync(_parameterLookup: ParameterLookup): Promise<void> {
		// Default implementation does nothing
		return Promise.resolve();
	}

	/** Updates the widget descriptor after the widget has been rendered. */
	public updateDescriptorAfterRender(_descriptor: WidgetDescriptor): void {
		// Default implementation does nothing
	}

	/**
	 * Rerenders the content based on previously loaded data.
	 *
	 * @param parameterLookup Function used to lookup parameter values.
	 */
	public refreshContent(
		parameterLookup: ParameterLookup,
		commit: UnresolvedCommitDescriptor | null,
		menuContext: WidgetMenuContextValues
	): void {
		this.removeContent();
		// No refresh in case of errors to not hide the error message
		if (this.errorsOccurred()) {
			this.renderContentViaReact(parameterLookup, commit, menuContext, <WidgetErrors errors={this.errors} />);
			return;
		}

		try {
			const content = this.renderContentAsReactNode(parameterLookup);
			this.renderContentViaReact(parameterLookup, commit, menuContext, content);
			if (content == null) {
				this.renderContent(parameterLookup);
			}
		} catch (error) {
			this.renderContentViaReact(parameterLookup, commit, menuContext, <WidgetErrorFallback error={error} />);
		}
	}

	/** Renders the widget's content via React. This should be preferred over #renderContent. */
	public renderContentAsReactNode(_parameterLookup: ParameterLookup): JSX.Element | null {
		return null;
	}

	/**
	 * Template method for rendering the content into the container element. This is called after all data has been
	 * loaded.
	 *
	 * @deprecated Use #renderContentAsReactNode instead
	 */
	public renderContent(_parameterLookup: ParameterLookup): void | Promise<void> {
		// Does nothing by default
	}

	/** Function used to report errors. */
	public error(message: string): void {
		this.errors.push(message);
	}

	/**
	 * Handler method for showing errors. Handles PromiseRejects and ServiceCallErrors gracefully and re-throws other
	 * errors (which shouldn't occur here).
	 */
	protected handleLoadingError(error: unknown): void {
		if (error instanceof ServiceCallError) {
			this.error(error.errorSummary);
			return;
		}
		console.error(error);
	}

	/** Function used to report warnings. */
	public warning(message: string): void {
		this.warnings.push(message);
	}

	/**
	 * Returns the project selected or 'unknown' if the parameterLookup is not a function or project not found.
	 *
	 * @param parameterLookup Function used to lookup parameter values.
	 */
	protected getProject(parameterLookup: ParameterLookup): string | null {
		const projectAndPath = parameterLookup(
			WidgetBase.PROJECT_PATH_PARAMETER.getName()
		) as ProjectPathParameterValue | null;
		if (projectAndPath != null) {
			return projectAndPath.project;
		}
		return null;
	}

	/**
	 * Returns the selected project and path.
	 *
	 * @param parameterLookup Function used to lookup parameter values.
	 */
	protected getProjectAndUniformPath(parameterLookup: ParameterLookup) {
		const projectPathParameterValue = parameterLookup(
			WidgetBase.PROJECT_PATH_PARAMETER.getName()
		) as ProjectPathParameterValue;
		return ProjectAndUniformPath.of(projectPathParameterValue.project, projectPathParameterValue.path);
	}

	/**
	 * Checks if this widget references a project by its internal id, which is discouraged.
	 *
	 * @param projectId The project reference in the widget
	 */
	private checkForProjectReferencedByInternalId(projectId: string): void {
		for (const projectInfo of this.getProjects()) {
			if (projectInfo.internalId === projectId) {
				this.error(
					'The path setting of this widget refers to a project by its internal ID, please use the public ID (' +
						projectInfo.publicIds[0]! +
						') instead'
				);
			}
		}
	}

	/**
	 * Returns the path selected.
	 *
	 * @param parameterLookup Function used to lookup parameter values.
	 */
	protected getPathInfo(parameterLookup: ParameterLookup): ProjectPathParameterValue {
		return parameterLookup(WidgetBase.PROJECT_PATH_PARAMETER.getName()) as ProjectPathParameterValue;
	}

	/**
	 * Retrieves the user-set value for the given parameter as the index in metricsSchema.
	 *
	 * @param paramNotSetError The error message to show, if the value is not set. Function for parameter lookup.
	 * @returns The metric index or null.
	 */
	public getMetricParameterIndex(
		param: SingleMetricParameter,
		paramNotSetError: string,
		parameterLookup: ParameterLookup,
		metricsSchema: MetricDirectorySchema
	): number | null {
		const metricName = parameterLookup(param.getName()) as string | null;
		if (metricName == null) {
			this.error(paramNotSetError);
			return null;
		}
		const metricIndex = MetricSchemaUtils.getMetricIndex(metricName, metricsSchema);
		if (metricIndex === null) {
			this.error("Metric doesn't exist in project: " + metricName);
		}
		return metricIndex;
	}

	/** @returns The default width and height in the layout grid. */
	public getDefaultSize(): Size {
		return new Size(4, 3);
	}

	/** @returns The minimal width and height in the layout grid. */
	public getMinSize(): Size {
		return new Size(2, 2);
	}

	/** This is called after the widget has been resized to trigger any rendering required to update the display. */
	public handleResize(): void {
		// Default implementation does nothing
	}

	/**
	 * Returns the metric indexes of the given names. This also performs error handling. In case of errors, these are
	 * reported and the method returns null. In case of an empty list of metric names, all metrics from the schema are
	 * returned.
	 */
	public getMetricIndexesFromMetricNames(
		metricNames: string[] | null,
		metricsSchema: MetricDirectorySchema
	): number[] | null {
		if (!metricNames || metricNames.length === 0) {
			metricNames = MetricSchemaUtils.getAllMetricNames(metricsSchema);
		}
		const missingMetrics: string[] = [];
		const metricsIndexes: number[] = [];
		for (const metricName of metricNames) {
			const index = MetricSchemaUtils.getMetricIndex(metricName, metricsSchema);
			if (index == null) {
				missingMetrics.push(metricName);
				continue;
			}
			metricsIndexes.push(index);
		}
		if (!ArrayUtils.isEmpty(missingMetrics)) {
			this.error("Metrics '" + missingMetrics.join("', '") + "' do not exist in project.");
			return null;
		}
		return metricsIndexes;
	}

	/**
	 * Validates a given list of parameters and sets an error message to any invalid parameter. Intended to be
	 * overridden by subclasses.
	 *
	 * @param parameterMap The available parameters stored in a map, accessible by their respective name.
	 */
	public validate(_parameterMap: Record<string, WidgetParameterBase>): void {
		//Default does nothing.
	}

	/**
	 * This method is called once after the rendering of the widget was completed. It can e.g. be used to show/hide
	 * parameters depending on the setting of another parameter.
	 */
	public runPostRenderActions(): void {
		// Default does nothing.
	}

	/**
	 * This method is called as soon a parameter has been changed. Note that for this to work, the
	 * WidgetParameterBase#appendChangeListener of the parameter has to be implemented!
	 *
	 * @param parameter The parameter that was changed.
	 */
	public onParameterChanged(_parameter: WidgetParameterBase): void {
		// Default does nothing.
	}

	/** Removes all error messages from all parameters of this widget, and enables the 'OK' button. */
	public resetParameterErrorsAndButton(): void {
		const parameterContainers = tsdom.getElementsByClass('parameter-container-marker', this.getDialogContainer());
		parameterContainers.forEach(container => {
			this.removeErrorMessagesFromParameter(Assertions.assertObject(container));
			container.classList.remove(WidgetBase.ERROR_STATE_CSS_CLASS);
		});
		this.setOkButtonEnabled(true);
	}

	/**
	 * Adds the given error message to the given parameters.
	 *
	 * @param parameters The parameters.
	 * @param message The error message.
	 */
	public addParameterError(parameters: WidgetParameterBase[], message: string): void {
		this.setOkButtonEnabled(false);
		parameters.forEach(parameter => {
			const container = parameter.getContainer()!;
			container.classList.add(WidgetBase.ERROR_STATE_CSS_CLASS);
			this.removeErrorMessagesFromParameter(container);
			container.appendChild(soy.renderAsElement(WidgetParameterTemplate.compactError, { message }));
		});
	}

	/** Removes (potential) error messages from the given parameter container. */
	public removeErrorMessagesFromParameter(parameterContainer: Element): void {
		const existingErrorElement = dom.getElementByClass('error-compact', parameterContainer);
		if (existingErrorElement) {
			tsdom.removeNode(existingErrorElement);
		}
	}

	/** Enables or disables the 'OK' button of the parameter settings. */
	public setOkButtonEnabled(enable: boolean): void {
		const okButton = dom.getElementByClass(
			'goog-buttonset-default',
			this.getDialogContainer()
		) as HTMLButtonElement;
		okButton.disabled = !enable;
	}

	/** @returns The container element of the parameter dialog. */
	public getDialogContainer(): Element | null {
		return dom.getElementByClass('modal-dialog');
	}

	/** Ignores trend setting, add warning describing the cause. */
	public ignoreTrendWithWarning(message: string): void {
		this.trendIgnored = true;
		this.warning(message);
	}

	/** Determines whether delta should be shown. */
	protected shouldShowDelta(trendTime: TypedPointInTime | null): boolean {
		return TimeUtils.isTrend(trendTime) && !this.trendIgnored;
	}
}

function Loading() {
	return (
		<div className="flex justify-center widget-loading">
			<Icon size="large" color="grey" name="spinner" className="loading" />
		</div>
	);
}

type WidgetFrameContentProps = {
	menuContext: WidgetMenuContextValues;
	title: JSX.Element | null;
	menu: JSX.Element;
	contentElement: HTMLElement | null;
	children: ReactNode;
};

function WidgetFrameContent({ menuContext, title, menu, contentElement, children }: WidgetFrameContentProps) {
	return (
		<>
			<WidgetMenuContext.Provider value={menuContext}>{menu}</WidgetMenuContext.Provider>
			{title}
			{contentElement
				? createPortal(
						<SuspendingErrorBoundary suspenseFallback={<Loading />} errorFallback={WidgetErrorFallback}>
							{children}
						</SuspendingErrorBoundary>,
						contentElement
					)
				: null}
		</>
	);
}

function WidgetErrorFallback({ error }: Pick<FallbackProps, 'error'>) {
	const errors = [];
	if (error instanceof Error) {
		errors.push(error.message.split('\n'));
	} else {
		errors.push(error.toString());
	}
	return <WidgetErrors errors={errors} />;
}

function WidgetErrors({ errors }: { errors: string[] }) {
	return (
		<>
			{errors.map((message, index) => (
				<Message error key={index}>
					<MessageHeader>Error: </MessageHeader>
					{message}
				</Message>
			))}
		</>
	);
}
